<template>
  <div>
    <page-header>
      <template v-slot:right>
        <el-button type="primary" @click="handleGotoEditPage()">添加视频模版</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" :model="searchForm">
              <el-form-item>
                <el-input v-model="searchForm.nickName" placeholder="昵称" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.mobile" placeholder="手机号" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-select v-model="searchForm.type" placeholder="类型" clearable>
                  <el-option :value="1" label="水印图片"></el-option>
                  <el-option :value="2" label="视频片头"></el-option>
                  <el-option :value="3" label="视频片尾"></el-option>
                </el-select>
              </el-form-item>
              <!--          <el-form-item label="系统模版">-->
              <!--            <el-checkbox v-model="searchForm.isPlatform"></el-checkbox>-->
              <!--          </el-form-item>-->
              <el-form-item>
                <el-button plain @click="handleSearch">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" width="55"></el-table-column>
              <el-table-column prop="nickName" label="用户昵称"></el-table-column>
              <el-table-column prop="mobile" label="用户手机号"></el-table-column>
              <el-table-column prop="type" label="模版类型">
                <template slot-scope="scope">
                  {{ getTplType(scope.row.type) }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="模版名称"></el-table-column>
              <!--          <el-table-column prop="userNo" label="用户编号"></el-table-column>-->
              <!--          <el-table-column prop="isPlatform" label="系统模版">-->
              <!--            <template slot-scope="scope">-->
              <!--              {{ scope.row.isPlatform ? '是' : '否' }}-->
              <!--            </template>-->
              <!--          </el-table-column>-->
              <el-table-column
                  label="操作"
                  width="200">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleRemove([scope.row.id])">删除</el-button>
                  <el-button type="text" @click="handleGotoEditPage(scope.row.id)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { UserVideoTplApi } from '@/api'
import { UserVideoTplType } from '@/utils/contants'

export default {
  name: 'VideoTpl',
  data () {
    return {
      searchForm: {
        nickName: '',
        mobile: ''
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false
    }
  },
  methods: {
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size,
        ...this.searchForm
      }

      if (this.searchForm.nickName) {
        params.nickName = this.searchForm.nickName
      }

      if (this.searchForm.mobile) {
        params.mobile = this.searchForm.mobile
      }

      UserVideoTplApi.list(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleGotoEditPage (id) {
      this.$router.push({
        name: 'UserMaterialVideoTplEdit',
        query: {
          id: id,
          t: Date.now()
        }
      })
    },
    handleRemove (id) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        UserVideoTplApi.remove(id).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {
      })
    },
    getTplType (val) {
      return UserVideoTplType.getText(val)
    }
  },
  created: function () {
    const mobile = this.$route.query.mobile
    if (mobile) {
      this.searchForm.mobile = mobile
    }
    this.handleSearch()
  }
}
</script>

<style>

</style>
